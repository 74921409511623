import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'

function startabusiness() {
	var d = new Date();
	var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	var n = month[d.getMonth()];
	var y = d.getFullYear();


    return (
		<PageWrapper footerDark>
		<Helmet>
			  <meta charSet="utf-8" />
			  <title>Banque pour auto-entrepreneurs - Meilleure banque pro pour les auto entrepreneurs</title>
			  <meta name="description" content="Vous êtes un auto-entrepreneur? Trouvez la meilleure banque pro avec bankbank." />
		  </Helmet>
		<Header/>
		<Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
		<Container style={{maxWidth: 1280, marginTop: 28}}>
	   
		<Breadcrumb>
		  <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
		  <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
		  <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque pro pour les auto-entrepreneurs?</Breadcrumb.Item>
		</Breadcrumb>


		<h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque pro pour les auto-entrepreneurs</h1>
		<h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
		<div id='onetwothreemobile'>
		<p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro pour les auto-entrepreneurs</span> pour déterminer vos priorités : tarifs et services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
		</div>

		<Disclosure/>
		<Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
		
		</Row>
		</Container>
		</Container>  


   
		<Container style={{maxWidth: 1280, marginTop: -130}}>
	  
		<Tabs style={{maxWidth: 1234}}>
  <TabList style={{marginLeft: 5}}>
	<Tab style={{marginLeft: 0, width: '100%', textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Auto-entrepreneur et micro-entreprise </Tab>
	{/* <Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 employé.es</Tab>
	<Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 employé.es</Tab>
	<Tab style={{marginLeft: 0}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Plus de 50 employé.es</Tab> */}
  </TabList>

  <TabPanel>
	<DataTable type='Société' what='autoentrepreneur' id='wideTable' classement='Note' order={true}/>
	<DataTableMobile type='Société' what='autoentrepreneur' id='mobileTable' classement='Note' order={true}/>
  </TabPanel>
	{/* <TabPanel>      
	<DataTable type='Société' id={[12,4,18,9,19,30,20]}/>
	<DataTableMobile type='Société' id={[12,4,18,9,19,30,20]}/>
  </TabPanel>
	<TabPanel>    
	<DataTable type='Société' id={[9,21,4,22,23,31]}/>
	<DataTableMobile type='Société' id={[9,21,4,22,23,31]}/>
  </TabPanel>
  <TabPanel>
	<DataTable type='Société' id={[9,21,5,22,23,24,25,26]}/>
	<DataTableMobile type='Société' id={[35,15,2,16,36,18,30,9,38]}/>
  </TabPanel> */}

</Tabs>
		  <Row className="justify-content-center">
			<Col>
		  
	
		  

	 
			</Col>
		  </Row>
		   
		</Container>
		<Container style={{marginTop: 100, marginBottom : 100}}>
       
			<Post>

			<h2>Comment choisir la meilleure banque pour un auto-entrepreneur et micro-entreprise ?
			</h2><p>Si la France connaît des années records en terme de création d’entreprise, c’est principalement la micro-entreprise qui connaît la croissance la plus forte.
			</p>
<StaticImage
		  	src='../../images/banque-pro-auto-entrepreneur.jpeg'
			width={1200}
			alt='Banque pro pour autoentrepreneurs'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<p>
Chaque micro-entreprise doit absolument détenir un compte bancaire pro indépendant de sa banque personnelle.
</p>

<h3>Une micro-entreprise (auto-entrepreneur), c’est quoi ?</h3>
<p>
Le régime des micro-entrepreneurs est destiné aux entreprises individuels (EI). Depuis 2017, les auto-entrepreneurs et les micro-entrepreneurs sont réunis sous le statut de micro-entreprise.
</p><p>
Si le statut de micro-entreprise est très avantageux, il faudra faire attention à bien respecter les différents plafonds de chiffre d’affaire et ainsi que les seuils de franchise TVA qui compliquera votre comptabilité.
</p>
<p>
Plafonds de CA :
</p>
<ul>
	<li>Achat / revente de marchandises : plafonds CA 176 000€</li>
	<li>Ventes de denrées à consommer sur place : plafonds CA 176 000€</li>
	<li>Prestations d’hébergement : plafonds CA 176 000€</li>
	<li>Prestations de service commerciales ou artisanales : plafonds CA 72 000€</li>
	<li>Activités libérales : plafonds CA 72 000€</li>
	
</ul>

<p>Seuils de franchise TV :</p>

<ul>
	<li>Achat / revente de marchandises : 85 800€</li>
	<li>Ventes de denrées à consommer sur place : 85 800€</li>
	<li>Prestations d’hébergement : 85 800€</li>
	<li>Prestations de service commerciales ou artisanales : 34 400€</li>
	<li>Activités libérales : 34 400€</li>

</ul>


<h3>La meilleure banque pro pour le liquide ?</h3>
<p>
C’est un moyen de paiement qui tend à disparaître, mais si vous encaissez beaucoup de paiement en espèce, nous n’aurez pas le choix. La banque traditionnelle est pour l’instant toujours à favoriser si vous avez beaucoup de transactions sous forme de liquide.
</p><h3>
Banque pro pour les chèques</h3>
<p>
Certaines activités comme le secteur de la construction a encore beaucoup recours aux chèques. La néobanque Qonto est pour l’instant la seule néobanque à accepter pleinement ce type de paiement.
</p>
<h3>
Pas de chèque ou de liquide ? Choisissez vraiment la meilleure banque pro!</h3>
<p>
Pour toutes les activités principalement basés sur le paiement en ligne ou les virements, l’offre bancaire est la plus large pour les micro-entrepreneurs et auto-entrepreneurs.
</p>
<ul>
	<li>Banque en ligne</li>
	<li>Néobanques pros</li>
	<li>Banques traditionnelles</li>
</ul>



			</Post>
		</Container>
		
		<Footer/>
      </PageWrapper>
    
    )
}

export default startabusiness
